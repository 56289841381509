import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";
import moment from "moment";

const AddAppointment = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const userId = useAuth().userId;
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState("07:00");
  const [endTime, setEndTime] = useState("07:00");
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [department, setDepartment] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [bookable, setBookable] = useState(false);
  const [added, setAdded] = useState(false);
  const [reason, setReason] = useState("");
  const [slot, setSlot] = useState("00");
  const [isRecurringAppointment, setIsRecurringAppointment] = useState(false);
  const [completionDate, setCompletionDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [recuringDays, setrecuringDays] = useState(false);
  let resultDays = [];
  const [result, setResults] = useState([]);
  const [bookableData, setBookableData] = useState([]);
  const [bookableDates, setBookableDates] = useState([]);
  const [notBookableDates, setNotBookableDates] = useState([]);
  const [isAppointmentBookable, setIsAppointmentBookable] = useState(true);

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/deptId/${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`temp : `, data);
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]}:${data[i]["name"]}:${data[i]["slot_duration"]}`
          );
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };

  const getClients = async () => {
    try {
      const response = await fetch(`${base_url}/client/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }

      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]};${data[i]["client_name"]};${data[i]["program_completion_date"]}`
          );
        }
        resolve(temp);
      });

      setClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleClear = () => {
    window.location.reload();
  };

  const handleDateChange = (selectedDate) => {
    // console.log(`set date : `, selectedDate);
    if (client == "") {
      toast.warn("Please select Client First!");
      return;
    }
    setDate(selectedDate);
  };

  const handleEndTimeChange = (selectedTime) => {
    setEndTime(selectedTime.target.value);
  };

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
    if (selected.length === 0) {
      setCompletionDate(null);
      setDate(null);
      return;
    }
    // find completion date from clients state
    const data = clients.find((ele) => ele.split(";")[1] == selected);
    const compDate = data.split(";")[2];
    const newDate = new Date(compDate);
    let today;

    if (date == null) {
      today = new Date(Date.now());
      // console.log(`today : `, today);
    } else {
      today = new Date(date);
    }

    if (newDate.getTime() <= today.getTime()) {
      toast.warn("Client is not in prescribe date range");
    } else {
      toast.success("Client is in prescribe date range");
    }
    // setCompletionDate(newDate.toISOString());
    const newCompDate = moment.utc(compDate).format("YYYY-MM-DD");
    // console.log(`new Comp : `, newCompDate);
    setCompletionDate(newCompDate);
  };

  function convertTimeToDate(timeString) {
    const date = new Date(timeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function checkBookable(dataToSend) {
    setLoading(true);

    const apiUrl = `${base_url}/appointment/bookable`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSend),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        let temp = [];
        let dates = [];
        let temp2 = [];
        let n = data.length;
        for (let i = 0; i < n; i++) {
          if (data[i].toBook == true) {
            temp.push(data[i]);
            dates.push(data[i].currDate);
          } else {
            temp2.push(data[i]);
          }
        }

        if (dates.length == 0) {
          setIsAppointmentBookable(false);
        }

        if (temp2.length != 0) {
          setIsAppointmentBookable(false);
        }

        setBookableData(temp);
        setBookableDates(dates);
        setNotBookableDates(temp2);
        setSuccess(true);
        setBookable(true);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function AddBooking(dataToSend) {
    setLoading(true);
    setSuccess(false);

    const apiUrl = `${base_url}/appointment/add`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSend),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        setSuccess(false);
        setAdded(true);
        setBookable(false);
        setrecuringDays(false);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (department.length == 0) {
      toast.warn("Please select Department First!");
      return;
    }

    if (subdepartment.length == 0) {
      toast.warn("Please select Sub-Department First!");
      return;
    }

    if (client.length == 0) {
      toast.warn("Please select Client First!");
      return;
    }

    if (date == null) {
      toast.warn("Please select Date First!");
      return;
    }

    if (isRecurringAppointment == true) {
      if (selectedDays.length == 0) {
        toast.warn("Please select Recurring Days First!");
        return;
      }
    }

    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(";")[0]);
        }
      });
      resolve(-1);
    });

    if (isRecurringAppointment == false) {
      if (bookable === false || success === false) {
        checkBookable({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: [convertTimeToDate(date)],
          isRecurring: false,
        });
      } else {
        AddBooking({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: [convertTimeToDate(date)],
          updatedBy: userId,
        });
      }
    } else {
      await getDatesForDays();

      if (bookable === false || success === false) {
        checkBookable({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: resultDays,
        });
      } else {
        AddBooking({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: bookableDates,
          updatedBy: userId,
        });
      }
    }
    setrecuringDays(true);
  };

  const handleStartTimeChange = (selectedTime) => {
    setStartTime(selectedTime.target.value);
    setEndTime(addTimeSlot(selectedTime.target.value, slot));
  };

  function setEndTimeBySlot(event) {
    const slotArray = Subdepartments.find(
      (subDepartment) => subDepartment.split(":")[0] == event
    );
    const data = slotArray.split(":")[2];
    setSlot(data);
    setEndTime(addTimeSlot(startTime, data));
  }

  function addTimeSlot(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(":").map(Number);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const dateTime = date.getTime() + minutesToAdd * 60000;

    const newDateTime = new Date(dateTime);

    const newHours = String(newDateTime.getHours()).padStart(2, "0");
    const newMinutes = String(newDateTime.getMinutes()).padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  }

  function handleAttendanceChange() {
    setIsRecurringAppointment(!isRecurringAppointment);
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedDays([...selectedDays, name]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== name));
    }
  };

  const getDatesForDays = () => {
    const startDate = new Date(date);
    const endDate = new Date(completionDate);
    const dayMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
    };

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const currentDay = currentDate.getDay();
      if (
        selectedDays.includes(
          Object.keys(dayMap).find((key) => dayMap[key] === currentDay)
        )
      ) {
        resultDays.push(convertTimeToDate(new Date(currentDate)));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setResults(resultDays);
  };

  useEffect(() => {
    getDepartments();
    getClients();
  }, []);

  return (
    <div className="">
      <h3 className="pt-4">Book an appointment</h3>
      <hr />
      <form onSubmit={handleSubmit}>
        {/* department */}
        <div className="mb-3 row">
          <label htmlFor="department" className="col-sm-2 col-form-label">
            Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="department"
              aria-label="Default select example"
              defaultValue=""
              onChange={async (e) => {
                if (isNaN(e.target.value) === false) {
                  await getSubdepartments(e.target.value);
                } else {
                  setSubdepartments([]);
                }
                setDepartment(e.target.value);
              }}
            >
              <option value="">Select a Department</option>
              {Departments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* subdepartment */}
        <div className="mb-3 row">
          <label htmlFor="subdepartment" className="col-sm-2 col-form-label">
            Sub Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="subdepartment"
              aria-label="Default select example"
              onChange={async (e) => {
                if (isNaN(e.target.value) === false) {
                  setEndTimeBySlot(e.target.value);
                } else {
                  setEndTime("01:00");
                }
                setSubdepartment(e.target.value);
              }}
            >
              <option selected>Select a Sub Department</option>
              {Subdepartments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* clients */}
        <div className="mb-3 row">
          <label htmlFor="client" className="col-sm-2 col-form-label">
            Client &nbsp;&nbsp;
          </label>

          <div className="col-sm-10">
            <Typeahead
              className="w-50"
              id="client"
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem.split(";")[1])}
              selected={client}
              placeholder="Select a Client"
            />
          </div>
        </div>

        {/* Dates */}
        <div className="mb-3 row">
          <label htmlFor="datePicker" className="col-sm-2 col-form-label">
            Date
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="datePicker"
              selected={date}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              className="form-control z-1"
              placeholderText="Select a Date"
              autoComplete="off"
            />
          </div>
        </div>

        {/* completion Date */}
        <div className="mb-3 row">
          <label
            htmlFor="completionDatePicker"
            className="col-sm-2 col-form-label"
          >
            Completion Date
          </label>
          <div className="col-sm-10">
            {/* <DatePicker
              id="completionDatePicker"
              selected={completionDate}
              dateFormat="MM/dd/yyyy"
              className="form-control z-1"
              disabled
            /> */}
            <input
              type="date"
              className="form-control w-50"
              value={completionDate}
              onChange={handleEndTimeChange}
              disabled
            />
          </div>
        </div>

        {/* Start time */}
        <div className="mb-3 row">
          <label htmlFor="starttime" className="col-sm-2 col-form-label">
            Start
          </label>
          <div className="col-sm-10">
            <input
              type="time"
              className="form-control w-50"
              value={startTime}
              onChange={(e) => handleStartTimeChange(e)}
            />
          </div>
        </div>

        {/* end Time */}
        <div className="mb-3 row">
          <label htmlFor="endtime" className="col-sm-2 col-form-label">
            End
          </label>
          <div className="col-sm-10">
            <input
              type="time"
              className="form-control w-50"
              value={endTime}
              onChange={handleEndTimeChange}
              disabled
            />
          </div>
        </div>

        {/* switch button */}
        <div class="form-check form-switch mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="recurring"
            onChange={handleAttendanceChange}
          />
          <label class="form-check-label" htmlFor="recurring">
            Recurring Appointments
          </label>
        </div>

        {isRecurringAppointment && (
          <div className="d-grid gap-2  p-2 ">
            {days.map((day) => (
              <div
                key={day}
                className="px-3 d-flex flex-row align-items-center "
              >
                <div style={{ width: "30px" }} className="">
                  <input
                    className="me-2"
                    type="checkbox"
                    role="switch"
                    id={day}
                    name={day}
                    autoComplete="off"
                    checked={selectedDays.includes(day)}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <label className="" htmlFor={day}>
                    {day}
                  </label>
                </div>

                <div style={{ width: "120px" }} className="me-5">
                  <input
                    type="time"
                    className="form-control"
                    value={startTime}
                    disabled
                  />
                </div>

                <div style={{ width: "120px" }}>
                  <input
                    type="time"
                    className="form-control"
                    value={endTime}
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {recuringDays && (
          <div className="d-flex gap-5">
            {bookableData.length != 0 && isAppointmentBookable && (
              <div className="alert alert-success mt-3 ">
                <span className="fw-bolder">
                  Slots are available for booking. Continue and Confirm Booking
                </span>{" "}
                <br />
              </div>
            )}

            {notBookableDates != 0 && (
              <div className="alert alert-danger mt-3 ">
                <span className="fw-bolder">
                  Booking confilcts as the client is already booked for these
                  slots. Please clear details and select some other slots.
                </span>{" "}
                <br />
                {notBookableDates.map((days) => (
                  <span>{days.currDate} , </span>
                ))}
              </div>
            )}
          </div>
        )}

        {bookable === false && success === true ? (
          <div className="alert alert-danger mt-3">
            Not Bookable Reason: {reason}
          </div>
        ) : (
          <div></div>
        )}

        {added === true ? (
          <div className="alert alert-success mt-3">Booked successfully!</div>
        ) : (
          <div></div>
        )}

        {isAppointmentBookable && (
          <div>
            {added === true ? (
              <div></div>
            ) : (
              <button
                type="Submit"
                className={
                  success & bookable
                    ? "btn btn-primary mb-3"
                    : "btn btn-primary mb-3"
                }
              >
                {bookable & success ? "Confirm Booking" : "Submit"}
              </button>
            )}
          </div>
        )}
      </form>
      <button onClick={handleClear} className="btn btn-danger">
        Clear Details
      </button>
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
    </div>
  );
};

export default AddAppointment;
