import { useAuth } from "../context/useAuth";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

// eslint-disable-next-line react/prop-types
export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const [authorized, setAuthorized] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const logout = useAuth().logout;

  const handleAuthorization = async () => {
    const response = await fetch(`${base_url}/verifyToken`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("verify");

    if (!response.ok) {
      console.log("notverify");
      setAuthorized(false);
      logout();
    } else {
      console.log("verifytrue");
      setAuthorized(true);
    }
  };

  // useEffect(() => {
  //   handleAuthorization();
  // }, []);

  if (!user) {
    // user is not authenticated

    return <Navigate to="/" />;
  }

  return children;
};
