import React, { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import AddADepartment from "./AddADepartment";
import { useAuth } from "../../../context/useAuth";
import UpdateDepartment from "./UpdateDepartment";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddDepartment = () => {
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isDepModalOpen, setIsDepModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);

  const getDepartment = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setTableData(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleDepartmentButtonClick = () => {
    setIsDepModalOpen(true);
  };

  const closeDepModal = async () => {
    setIsDepModalOpen(false);
    await getDepartment();
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getDepartment();
  };

  const handleActionClick = (e) => {
    const newData = tableData.filter((value) => {
      return value.split(":")[0] === e;
    });

    // console.log(`newData:  `, newData);

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  useEffect(() => {
    getDepartment();
  }, [isDepModalOpen]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Departments</h3>
      <hr />

      <div className="row mt-1 ms-1">
        <button
          className="btn btn-primary me-5"
          type="button"
          style={{ width: "210px" }}
          onClick={handleDepartmentButtonClick}
        >
          Add A Department
        </button>
      </div>

      <AddADepartment isOpen={isDepModalOpen} onClose={closeDepModal} />
      {isActionModalopen && (
        <UpdateDepartment
          isOpen={isActionModalopen}
          onClose={closeActionModal}
          data={actionModalData}
        />
      )}
      <div className="mt-4">
        <div className="card mb-4">
          <div className="card-header text-center">Department Details</div>
          <div className="container-fluid px-0">
            <table border="1" className="table table-striped table-hover">
              <thead>
                <tr className="text-center">
                  <th scope="col">Sr. No. </th>
                  <th scope="col">Department Name </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {tableData.map((row, index) => (
                  <tr className="py-2" key={row.ClientID}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{row.split(":")[1]}</td>
                    <td className="align-middle">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleActionClick(row.split(":")[0])}
                      >
                        Action
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDepartment;
