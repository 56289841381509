import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import AddClient from "./AddClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { CSVDownload } from "react-csv";
import { useAuth } from "../../../context/useAuth";
import UpdateClient from "./UpdateClient";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
// import { toast } from "react-toastify";

const Clients = () => {
  const location = useLocation();
  const { Clientvalue } = location.state || "";
  const today = new Date(Date.now());
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [data1, setData1] = useState([]);
  var [data2, setData2] = useState([]);
  var [data3, setData3] = useState([]);
  const [ComplianceRate, setComplianceRate] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredValue, setFilteredValue] = useState(Clientvalue);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);

  const [download, setDownload] = useState(false);

  var dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Status",
      "Attendance",
      "Date",
    ],
  ];

  var [csvData, setCsvData] = useState(dataForCSV);
  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  function findValueAfterColon(type, Id) {
    if (type == "dept") {
      for (var i = 0; i < Departments.length; i++) {
        if (Departments[i].split(":")[0] == Id.toString()) {
          return Departments[i].split(":")[1];
        }
      }
    } else if (type == "subdept") {
      for (var j = 0; j < Subdepartments.length; j++) {
        if (Subdepartments[j].split(":")[0] == Id.toString()) {
          return Subdepartments[j].split(":")[1];
        }
      }
    } else {
      for (var k = 0; k < clients.length; k++) {
        if (clients[k].split(":")[0] == Id.toString()) {
          return clients[k].split(":")[1];
        }
      }
    }
  }

  function convertTimeToDate(dateString) {
    const newDate = moment.utc(dateString).format("MMM Do YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleClientButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await getClients();
  };

  const convertDateTime = (dateString) => {
    const newDate = moment.utc(dateString).local().format("MMM Do YYYY");
    return newDate;
  };

  const getClients = async () => {
    try {
      const response = await fetch(`${base_url}/client/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      var temp = [];
      var temp2 = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
          temp2.push(data[i]);
        }
        resolve(temp);
      });

      var temp1 = [];

      for (var i = 0; i < temp2.length; i++) {
        temp1.push({
          ClientID: temp2[i]["id"],
          name: temp2[i]["client_name"],
          IntakeDate: temp2[i]["program_intake_date"],
          ProgramCompletionDate: temp2[i]["program_completion_date"],
          ActiveStatus: temp2[i]["status"],
          ComplianceRate: temp2[i]["ComplianceRate"],
          Email: temp2[i]["email"],
          Phone: temp2[i]["phone"],
          updated_at: temp2[i]["updated_at"],
        });
      }

      setClients(temp);
      setFilteredData(temp1);
      setData2(temp1);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(`e : `, e)
    setLoading(true);
    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(":")[0]);
        }
      });
      resolve(-1);
    });

    setData3(data2.filter((elem) => elem["ClientID"] == clientID));

    const apiUrl = `${base_url}/appointment/getByClient`;
    var payload = {
      clientID: clientID,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        var result = await response.json();

        // console.log(`result : `, result);
        var temp = [];
        var sum = 0;

        for (var i = 0; i < result.length; i++) {
          sum = sum + result[i]["attendance"];
          temp.push({
            AppointmentID: result[i]["id"],
            ClientID: result[i]["client_id"],
            ClientName: result[i]["client_name"],
            DepartmentName: result[i]["department_name"],
            SubdepartmentName: result[i]["sub_department_name"],
            StartTime: result[i]["start_time"],
            EndTime: result[i]["end_time"],
            // Status: result[i]["Status"],
            Attendance: result[i]["attendance"],
            Date: result[i]["date"],
          });
          dataForCSV.push([
            result[i]["id"],
            findValueAfterColon("client", result[i]["client_id"]),
            findValueAfterColon("dept", result[i]["department_id"]),
            findValueAfterColon("subdept", result[i]["sub_department_id"]),
            convertTimeToHHMM(result[i]["start_time"]),
            convertTimeToHHMM(result[i]["end_time"]),
            // result[i]["Status"] == 1 ? "Active" : "Inactive",
            result[i]["attendance"]
              ? "Present"
              : result[i]["attendance"]
              ? "Absent"
              : "Null",
            convertTimeToDate(result[i]["date"]),
          ]);
        }

        setData1(temp);

        var length = temp.length;
        var Total = parseInt(sum) / parseInt(length);
        setComplianceRate(Total * 100);

        setVisible(true);
        setActiveDropdown(false);
        setCsvData(dataForCSV);
        setLoading(false);
      })
      .then((data) => {
        // console.log("Data sent successfully:", data);
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleClientDropdownChange(e) {
    setFilteredValue(e);

    if (e === "All") {
      setFilteredData(data2);
    }

    if (e === "Active") {
      setFilteredData(data2.filter((element) => element.ActiveStatus === 1));
    }

    if (e === "Inactive") {
      setFilteredData(data2.filter((element) => element.ActiveStatus === 0));
    }
  }

  const handleActionClick = (e) => {
    const newData = filteredData.filter((value) => {
      return value.ClientID === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getClients();
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    handleClientDropdownChange(Clientvalue);
  }, [data2]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Clients</h3>
      <hr />
      <button
        type="button"
        onClick={handleClientButtonClick}
        className="btn btn-primary mb-3"
      >
        Add a Client
      </button>
      <form onSubmit={handleSubmit} className="">
        <div className=" row">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-50"
              id="client"
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem.split(":")[1])}
              selected={client}
              placeholder="Select a Client"
            />
            {loading ? (
              <div className="" role="status">
                <span className="">Loading...</span>
              </div>
            ) : (
              <button
                type="Submit"
                className="btn btn-primary "
                style={{ width: "100px" }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <AddClient isOpen={isModalOpen} onClose={closeModal} />
      </form>
      {activeDropdown && (
        <div>
          <select
            id="dropdown"
            value={filteredValue}
            onChange={(e) => {
              handleClientDropdownChange(e.target.value);
            }}
            className="form-select w-25 mt-3"
            aria-label="Default select example"
          >
            <option value="All">All Clients</option>
            <option value="Active">Active Clients</option>
            <option value="Inactive">InActive Client</option>
          </select>
        </div>
      )}

      <br />
      {visible ? (
        <>
          <button
            type="button"
            className="btn btn-success"
            onClick={downloadCSV}
          >
            Download CSV
          </button>{" "}
          &nbsp;&nbsp;
          {download ? (
            <CSVDownload data={csvData} target="_blank" />
          ) : (
            <></>
          )}{" "}
          <hr />
          <div className="card mb-4">
            <div className="card-header text-center">Appointment Details</div>
            <div className="container-fluid px-0">
              <table border="1" className="table table-striped table-hover">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Id</th>
                    <th scope="col">Client </th>
                    <th scope="col">Department </th>
                    <th scope="col">Subdepartment </th>
                    <th scope="col">Start Time </th>
                    <th scope="col">End Time </th>
                    {/* <th scope="col">Status </th> */}
                    <th scope="col">Attendance </th>
                    <th scope="col">Date </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data1.map((row) => (
                    <tr key={row.AppointmentID}>
                      <td>{row.AppointmentID}</td>
                      <td>{row.ClientName}</td>
                      <td>{row.DepartmentName}</td>
                      <td>{row.SubdepartmentName}</td>
                      <td>{convertTimeToHHMM(row.StartTime)}</td>
                      <td>{convertTimeToHHMM(row.EndTime)}</td>
                      <td>
                        {row.Attendance
                          ? "Present"
                          : row.attendance === 0
                          ? "Absent"
                          : "Null"}
                      </td>
                      <td>{convertTimeToDate(row.Date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <br /> <br />
          <div className="card mb-4">
            <div className="card-header text-center">Client Details</div>
            <div className="container-fluid px-0">
              <table border="1" className="table table-striped table-hover">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Id </th>
                    <th scope="col">Name </th>
                    <th scope="col">Intake Date </th>
                    <th scope="col">Program Completion Date </th>
                    <th scope="col">Active Status </th>
                    <th scope="col">Compliance Rate </th>
                    <th scope="col">Email </th>
                    <th scope="col">Phone </th>
                    <th scope="col">Updated Time</th>
                    {/* <th scope="col"></th> */}
                  </tr>
                </thead>
                <tbody className="text-center ">
                  {data3.map((row) => (
                    <tr className="align-middle" key={row.ClientID}>
                      <td className="align-middle">{row.ClientID}</td>
                      <td className="align-middle">
                        {findValueAfterColon("client", row.ClientID)}
                      </td>
                      <td className="align-middle">
                        {convertTimeToDate(row.IntakeDate)}
                      </td>
                      <td
                        className={`align-middle ${
                          new Date(row.ProgramCompletionDate).getTime() <=
                          today.getTime()
                            ? "fw-bolder text-danger"
                            : ""
                        } `}
                      >
                        {convertTimeToDate(row.ProgramCompletionDate)}
                      </td>
                      <td
                        className={`align-middle ${
                          row.ActiveStatus == 0 ? "fw-bolder text-danger" : ""
                        } `}
                      >
                        {row.ActiveStatus == 1 ? "Active" : "Inactive"}
                      </td>
                      <td className="align-middle">{ComplianceRate}</td>
                      <td className="align-middle">
                        {row.Email == undefined ? "No Email" : row.Email}
                      </td>
                      <td className="align-middle">
                        {row.Phone == undefined ? "No Phone" : row.Phone}
                      </td>
                      <td className="align-middle">
                        {new Date(row.updated_at).toLocaleString("en-US", {
                          timeZone: "America/New_York",
                        })}{" "}
                        ET
                      </td>
                      <td className="align-middle">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleActionClick(row.ClientID)}
                        >
                          Action
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isActionModalopen && (
                <UpdateClient
                  isOpen={isActionModalopen}
                  onClose={closeActionModal}
                  data={actionModalData}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card mb-4">
            <div className="card-header text-center">
              {filteredValue} Client Details
            </div>
            <div className="container-fluid px-0">
              <table border="1" className="table table-striped table-hover">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Id </th>
                    <th scope="col">Name </th>
                    <th scope="col">Intake Date </th>
                    <th scope="col">Program Completion Date </th>
                    <th scope="col">Active Status </th>
                    <th scope="col">Email </th>
                    <th scope="col">Phone </th>
                    <th scope="col">Updated Time</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {filteredData.map((row) => (
                    <tr className=" " key={row.ClientID}>
                      <td className="align-middle">{row.ClientID}</td>
                      <td className="align-middle">
                        {findValueAfterColon("client", row.ClientID)}
                      </td>
                      <td className="align-middle">
                        {convertTimeToDate(row.IntakeDate)}
                      </td>
                      <td
                        className={`align-middle ${
                          new Date(row.ProgramCompletionDate).getTime() <=
                          today.getTime()
                            ? "fw-bolder text-danger"
                            : ""
                        } `}
                      >
                        {convertTimeToDate(row.ProgramCompletionDate)}
                      </td>
                      <td
                        className={`align-middle ${
                          row.ActiveStatus == 0 ? "fw-bolder text-danger" : ""
                        } `}
                      >
                        {row.ActiveStatus == 1 ? "Active" : "Inactive"}
                      </td>
                      <td className="align-middle">
                        {row.Email == "" ? "No Email" : row.Email}
                      </td>
                      <td className="align-middle">
                        {row.Phone == "" ? "No Phone" : row.Phone}
                      </td>
                      <td className="align-middle">
                        {new Date(row.updated_at).toLocaleString("en-US", {
                          timeZone: "America/New_York",
                        })}{" "}
                        ET
                      </td>
                      <td className="align-middle">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleActionClick(row.ClientID)}
                        >
                          Action
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isActionModalopen && (
                <UpdateClient
                  isOpen={isActionModalopen}
                  onClose={closeActionModal}
                  data={actionModalData}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Clients;
