import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { UserRoute } from "./routes/UserRoute";
import { SuperAdminControl } from "./routes/SuperAdminControl";
import Login from "./pages/Login";
import CalendarPage from "./pages/CalendarPage";
import DashboardPage from "./pages/DashboardPage";
import AppointmentPage from "./pages/AppointmentPage";
import ReportsPage from "./pages/ReportsPage";
import DepartmentPage from "./pages/DepartmentPage";
import ClientsPage from "./pages/ClientsPage";
import UserPage from "./pages/UserPage";
import SubDepartmentPage from "./pages/SubDepartmentPage";
import ShilohAlignmentPage from "./pages/ShilohAlignmentPage";
import AuthVerify from "./routes/AuthVerify";

function App() {
  return (
    <>
      {/* <AuthProvider> */}
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <UserRoute>
                <Login />
              </UserRoute>
            }
          ></Route>

          <Route
            exact
            path="/dashboard"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/calendar"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <CalendarPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/appointment"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <AppointmentPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/reports"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <ReportsPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/department"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <SuperAdminControl>
                  <DepartmentPage />
                </SuperAdminControl>
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/subdepartment"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <SuperAdminControl>
                  <SubDepartmentPage />
                </SuperAdminControl>
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/client"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <ClientsPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/shilohAlignment"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <ShilohAlignmentPage />
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>

          <Route
            exact
            path="/user"
            element={
              // <AuthVerify>
              <ProtectedRoute>
                <SuperAdminControl>
                  <UserPage />
                </SuperAdminControl>
              </ProtectedRoute>
              // </AuthVerify>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
