import { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { CSVDownload } from "react-csv";
import { useAuth } from "../../../context/useAuth";
import AddUser from "./AddUser";
import LogoutModal from "../../logoutModal/LogoutModal";
import { toast } from "react-toastify";

const User = () => {
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);

  const [download, setDownload] = useState(false);

  var dataForCSV = [
    ["UserId", "Name", "Role", "Department Id", "Department Name"],
  ];

  var [csvData, setCsvData] = useState();
  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  const handleClientButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await getUsers();
  };

  const getUsers = async () => {
    try {
      const response = await fetch(`${base_url}/user/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();


      for (var i = 0; i < data.length; i++) {
        dataForCSV.push([
          data[i]["UserID"],
          data[i]["UserName"],
          data[i]["Role"],
          data[i]["DepartmentID"],
          data[i]["DepartmentName"],
        ]);
      }
      setCsvData(dataForCSV);
      setData(data);
    } catch (err) {
      console.log(`error`);
    }
  };

  const handleDelete = (e) => {

    const apiUrl = `${base_url}/user/delete/${e}`;
    alert("Are you sure you want to delete this user?");
    // console.log(`data : `, e);
    // return;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if(response.status === 404){
          toast.warn("appointment is already present for this subdepartment. Please delete that appointment first");
          return;
        }else if(response.status === 200){
          toast.success("user deleted successfully");
          getUsers();
          return;
        }else{
          setIsExpire(true);
          return;
        }
      })
      .catch((error) => {
        toast.error("Error deleting user");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        // onClose();
      });
  }

  function findUserName(e) {
    const userName = data.find((element) => element.UserID === e);

    if (userName != null) {
      return userName.UserName;
    } else {
      return "";
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">User</h3>
      <hr />

      <div className="col">
        <div className="row mt-2">
          <button
            type="button"
            onClick={handleClientButtonClick}
            className="btn btn-primary ms-3 mb-3"
            style={{ width: "180px" }}
          >
            Add User
          </button>
        </div>
      </div>
      <AddUser isOpen={isModalOpen} onClose={closeModal} />

      <>
        <button
          type="button"
          className="btn btn-success mb-3"
          onClick={downloadCSV}
        >
          Download CSV
        </button>
        {download ? <CSVDownload data={csvData} target="_blank" /> : <></>}
      </>
      <>
        <div className="card mb-4">
          <div className="card-header text-center">All User Details</div>
          <div className="container-fluid px-0">
            <table border="1" className="table table-striped table-hover">
              <thead>
                <tr className="text-center">
                  <th scope="col">Id </th>
                  <th scope="col">Name </th>
                  <th scope="col">Role </th>
                  <th scope="col">Department Id </th>
                  <th scope="col">Department Name </th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated Time</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {data.map((row) => (
                  <tr className="py-2" key={row.UserID}>
                    <td className="py-2">{row.UserID}</td>
                    <td className="py-2">{row.UserName}</td>
                    <td className="py-2">{row.Role}</td>
                    <td className="py-2">{row.DepartmentID}</td>
                    <td className="py-2">{row.DepartmentName}</td>
                    <td className="py-2">{findUserName(row.updatedBy)}</td>
                    {/* <td className="py-2">{row.updatedTime}</td> */}
                    <td className="py-2">
                      {new Date(row.updatedTime).toLocaleString("en-US", {
                        timeZone: "America/New_York",
                      })}{" "}
                      ET
                    </td>

                    <td className="py-2">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(row.UserID)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default User;
