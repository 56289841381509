import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import AddASubDepartment from "./AddASubDepartment";
import UpdateSubDepartment from "./UpdateSubDepartment";
import LogoutModal from "../../logoutModal/LogoutModal";

const SubDepartment = () => {
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isSubDepModalOpen, setIsSubDepModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);

  const getDepartmentSubdepartment = async () => {
    try {
      const response = await fetch(`${base_url}/department/getDepSubDep`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`data from department table: `, data);
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["departmentId"]}:${data[i]["departmentName"]}:${data[i]["subDepartmentName"]}:${data[i]["timeSlot"]}:${data[i]["subdepartmentId"]}:${i}`
          );
        }
        resolve(temp);
      });
      // console.log(`Departments Data on parent component: `, temp);
      setTableData(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleSubDepartmentButtonClick = () => {
    setIsSubDepModalOpen(true);
  };

  const closeSubDepModal = async () => {
    setIsSubDepModalOpen(false);
    await getDepartmentSubdepartment();
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getDepartmentSubdepartment();
  };

  const handleActionClick = (e) => {
    const newData = tableData.filter((value) => {
      return value.split(":")[5] === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  useEffect(() => {
    getDepartmentSubdepartment();
  }, [isSubDepModalOpen]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Sub Departments</h3>
      <hr />

      <div className="row mt-1 ms-1">
        <button
          className="btn btn-primary"
          type="button"
          style={{ width: "210px" }}
          onClick={handleSubDepartmentButtonClick}
        >
          Add A Sub Department
        </button>
      </div>

      <AddASubDepartment
        isOpen={isSubDepModalOpen}
        onClose={closeSubDepModal}
      />
      {isActionModalopen && (
        <UpdateSubDepartment
          isOpen={isActionModalopen}
          onClose={closeActionModal}
          data={actionModalData}
        />
      )}
      <div className="mt-4">
        <div className="card mb-4">
          <div className="card-header text-center">Sub Department Details</div>
          <div className="container-fluid px-0">
            <table border="1" className="table table-striped table-hover">
              <thead>
                <tr className="text-center">
                  <th scope="col">Sr. No. </th>
                  <th scope="col">Department Name </th>
                  <th scope="col">Sub Department Name </th>
                  <th scope="col">Time Slot</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {tableData.map((row, index) => (
                  <tr className="py-2" key={row.ClientID}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{row.split(":")[1]}</td>
                    <td className="align-middle">
                      {row.split(":")[2] == "null"
                        ? ""
                        : `${row.split(":")[2]}`}
                    </td>
                    <td className="align-middle">
                      {row.split(":")[3] == "null"
                        ? ""
                        : `${row.split(":")[3]}`}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleActionClick(row.split(":")[5])}
                      >
                        Action
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubDepartment;
