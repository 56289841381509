import { createContext, useContext, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const [role, setRole] = useLocalStorage("role", null);
  const [userId, setUserId] = useLocalStorage("userID", null);
  const [tokenExpire, setTokenExpire] = useLocalStorage("tokenExpire", null);

  const login = async (data) => {
    setUser(data.username);
    setToken(data.token);
    setRole(data.role);
    setUserId(data.userId);

    // console.log()

    const decodedToken = JSON.parse(atob(data.token.split(".")[1]));
    const expirationTime = decodedToken.exp * 1000;
    setTokenExpire(expirationTime);

    return <Navigate to="/" />;
  };

  // const tokenExpire = useAuth().tokenExpire;
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!tokenExpire || new Date().getTime() > tokenExpire) {
  //     navigate("/");
  //   }
  // }, [tokenExpire]);

  const logout = () => {
    setUser(null);
    setToken(null);
    setRole(null);
    setUserId(null);
    setTokenExpire(null);
  };

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout,
      role,
      userId,
      tokenExpire,
    }),
    [user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
